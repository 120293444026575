import React from 'react';
import FlexContainer from '../global/containers/FlexContainer';
import BasicContainer from '../global/containers/BasicContainer';
import styled, { ThemeProvider } from 'styled-components';
import { ligthTheme } from '../theme/themes';
import Menu from '../global/components/Menu';
import Playlists from './components/Playlists';
import Musics from './components/Musics';

const StyledLayout = styled(FlexContainer)`
    height: 100%;
`;

const MenuContainer = styled(BasicContainer)`
    width: 25%;
    height: 100%;
`;

const PlaylistContainer = styled(BasicContainer)`
    width: 25%;
    height: 100%;
`;

const MusicsContainer = styled(BasicContainer)`
    width: 50%;
    height: 100%;
`;

const Dashboard = () => {
    return (
        <StyledLayout>
            <MenuContainer>
                <Menu />
            </MenuContainer>
            <ThemeProvider theme={ligthTheme}>
                <PlaylistContainer>
                    <Playlists />
                </PlaylistContainer>
                <MusicsContainer>
                    <Musics />
                </MusicsContainer>
            </ThemeProvider>
        </StyledLayout>
    );
};

export default Dashboard;
