const feathers = require('@feathersjs/feathers');
const rest = require('@feathersjs/rest-client');
const app = feathers();
const restClient = rest(process.env.REACT_APP_API_URL_CLIENT);
const auth = require('@feathersjs/authentication-client');

app.configure(restClient.fetch(window.fetch));
app.configure(
    auth({
        storageKey: 'auth',
    }),
);

export default app;
