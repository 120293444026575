import React, { useState, useContext, createContext } from 'react';

export const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [modalContent, setModalContent] = useState(null);

    const close = () => {
        setModalContent(null);
    };

    const open = (modal) => {
        setModalContent(modal);
    };

    return (
        <ModalContext.Provider
            value={{
                modalContent,
                close,
                open,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};
