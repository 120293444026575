import React, { useState } from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/COLORS';
import FlexContainer from '../containers/FlexContainer';
import Text from './Text';

const StyledListItem = styled(FlexContainer)`
    width: 100%;
    padding: 25px 0px;
    border-bottom: 1px solid ${(props) => props.dividerColor};
    position: relative;
`;

const ContextMenuWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 55px;
    z-index: 2;
    display: ${(props) => (props.openMenu ? 'normal' : 'none')};
`;

const ListItem = ({ item, dividerColor, contextMenu, onSelect, selected }) => {
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <StyledListItem
            className="pointer"
            alignItems="center"
            justifyContent="space-between"
            dividerColor={dividerColor}
            onMouseLeave={() => setOpenMenu(false)}
            onClick={onSelect}
        >
            <Text fontSize="23px" uppercase color={selected ? COLORS.RED : undefined}>
                {item.name}
            </Text>
            <img className="pointer" alt="Logo Menu" src="/assets/Icon/options.png" width="30px" onClick={() => setOpenMenu(!openMenu)} />
            <ContextMenuWrapper openMenu={openMenu}>{contextMenu}</ContextMenuWrapper>
        </StyledListItem>
    );
};

export default ListItem;
