import { useEffect, useState } from 'react';
import { useAuth } from '../context/authContext';

const useAutologin = () => {
    const { autoLogin } = useAuth();
    const [load, setLoad] = useState(true);

    useEffect(() => {
        const launchAutologin = async () => {
            await autoLogin();
            setLoad(false);
        };
        launchAutologin();
    }, [autoLogin]);

    return load;
};

export default useAutologin;
