import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import { useAuth } from '../context/authContext';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';

const Routes = () => {
    const { isAuthenticated } = useAuth();

    const routes = [
        { auth: false, path: '/login', component: <Login /> },
        { auth: true, path: '/dashboard', component: <Dashboard /> },
    ];

    return (
        <Switch>
            {routes.map((r) =>
                !r.auth ? (
                    <Route key={r.path} exact path={r.path}>
                        {r.component}
                    </Route>
                ) : (
                    <AuthenticatedRoute key={r.path} exact path={r.path}>
                        {r.component}
                    </AuthenticatedRoute>
                ),
            )}
            <Route path="*">{isAuthenticated ? <Redirect to="/login" /> : <Redirect to="/login" />}</Route>
        </Switch>
    );
};

export default Routes;
