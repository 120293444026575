import React from 'react';
import styled, { useTheme } from 'styled-components';

const StyledScrollbarWrapper = styled.div`
    height: ${(props) => props.boxHeight};
    width: ${(props) => props.width};
    padding: ${(props) => props.padding};
    box-sizing: border-box;
    overflow-y: ${(props) => props.overflowY};
    overflow-x: ${(props) => props.overflowX};
    border-top: 1px solid ${(props) => props.dividerColor};
    padding-top: 0px;

    ::-webkit-scrollbar {
        width: ${(props) => props.trackWidth}px;
        height: ${(props) => props.trackWidth}px;
    }
    ::-webkit-scrollbar-track {
        background-color: ${(props) => props.theme.colors.scrollbar};
        border-radius: 50vw;
        margin: ${(props) => props.margin}px ${(props) => (props.horizontal ? props.margin : 0)}px ${(props) => props.margin}px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.color};
        border-radius: 50vw;
    }
`;

const CustomScrollbarWrapper = ({
    children,
    boxHeight,
    trackWidth = 5,
    color,
    margin = 10,
    width = '100%',
    padding = '10px',
    overflowY = 'auto',
    overflowX = 'auto',
    dividerColor = 'transparent',
}) => {
    const theme = useTheme();
    const usedColor = color ?? theme.colors.active;
    return (
        <StyledScrollbarWrapper
            color={usedColor}
            boxHeight={boxHeight}
            trackWidth={trackWidth}
            margin={margin}
            width={width}
            padding={padding}
            overflowY={overflowY}
            overflowX={overflowX}
            horizontal={false}
            dividerColor={dividerColor}
        >
            {children}
        </StyledScrollbarWrapper>
    );
};

export default CustomScrollbarWrapper;
