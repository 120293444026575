import React, { useState, useContext, createContext } from 'react';

export const DataContext = createContext();

export const useStore = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [etab, setEtab] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userPlaylists, setUserPlaylists] = useState(null);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);

    return (
        <DataContext.Provider
            value={{
                etab,
                selectedUser,
                userPlaylists,
                selectedPlaylist,
                setEtab,
                setSelectedUser,
                setUserPlaylists,
                setSelectedPlaylist,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};
