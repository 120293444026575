import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import COLORS from '../constants/COLORS';
import Button from '../global/components/Button';
import Input from '../global/components/Input';
import Text from '../global/components/Text';
import Spacer from '../global/components/Spacer';
import Title from '../global/components/Title';
import FlexContainer from '../global/containers/FlexContainer';
import generatePassword from '../utils/utils';
import CustomImagePicker from '../global/components/CustomImagePicker';
import app from '../utils/feathersClient';
import { useModal } from '../context/modalContext';
import { useStore } from '../context/dataContext';

const CreateUserForm = () => {
    const { close } = useModal();
    const { setEtab } = useStore();

    const [creationStep, setCreationStep] = useState(false);
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState(generatePassword());

    const [isCopied, setIsCopied] = useState(false);

    const handleProfilCreation = async () => {
        try {
            const reader = new FileReader();
            if (image) {
                reader.onload = async (e) => {
                    console.log(e.target.value);
                    await app.service('users').create({ username, password, name, uri: e.target.result });
                    const res = await app.service('users').find({ query: { isAdmin: false } });
                    setEtab(res);
                    close();
                };
                reader.readAsDataURL(image);
            } else {
                await app.service('users').create({ username, password, name });
                const res = await app.service('users').find({ query: { isAdmin: false } });
                setEtab(res);
                close();
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <FlexContainer direction="column" justifyContent="center" alignItems="center" onClick={(e) => e.stopPropagation()}>
            <Title color={COLORS.WHITE} fontSize="35px" style={{ borderBottom: '1px white solid', padding: '0px 50px 25px 50px' }}>
                Create a new profil
            </Title>
            {!creationStep && (
                <>
                    <CustomImagePicker getImage={setImage} />
                    <Spacer height="20px" />
                    <FlexContainer alignItems="center">
                        <Text fontSize="30px" margin="0px 20px 0px 0px">
                            Name
                        </Text>
                        <Input value={name} onChange={(e) => setName(e.target.value)} fromModal placeholder="Name" />
                    </FlexContainer>
                    <Spacer height="20px" />
                    <Button
                        rounded
                        fontSize="35px"
                        fromModal
                        primaryColor="transparent"
                        onClick={() => setCreationStep(true)}
                        disabled={!name}
                    >
                        Continue
                    </Button>
                </>
            )}
            {creationStep && (
                <>
                    <FlexContainer alignItems="center">
                        <Text fontSize="30px" margin="0px 20px 0px 0px">
                            Username
                        </Text>
                        <Input value={username} onChange={(e) => setUsername(e.target.value)} fromModal placeholder="Username" />
                    </FlexContainer>
                    <Spacer height="20px" />
                    <FlexContainer alignItems="center">
                        <Text fontSize="30px" margin="0px 20px 0px 0px">
                            Password
                        </Text>
                        <Input value={password} onChange={(e) => setPassword(e.target.value)} fromModal placeholder="Password" />
                    </FlexContainer>
                    <Spacer height="20px" />
                    <Button
                        rounded
                        fontSize="35px"
                        fromModal
                        primaryColor="transparent"
                        onClick={async () => {
                            await navigator.clipboard.writeText(password);
                            setIsCopied(true);
                        }}
                        width="200px"
                    >
                        Copy
                    </Button>
                    <Spacer height="20px" />
                    <Button
                        width="200px"
                        rounded
                        fontSize="35px"
                        fromModal
                        primaryColor="transparent"
                        disabled={!username || !password}
                        onClick={handleProfilCreation}
                    >
                        Create
                    </Button>
                </>
            )}
        </FlexContainer>
    );
};

export default CreateUserForm;
