import styled from 'styled-components';
import COLORS from '../../constants/COLORS';
import CustomScrollbarWrapper from './CustomScrollbarWrapper';
import moment from 'moment';
import app from '../../utils/feathersClient';
import { useState, useEffect } from 'react';

const StyledTable = styled.div`
    .row {
        font-family: 'CynthoNext-Light';
        color: ${(props) => props.theme.colors.contrast};
        font-size: 15px;
        padding: 8px 0px;
        display: flex;
    }

    .header {
        font-family: 'CynthoNext-Light';
        font-size: 15px;
        border-bottom: 1px solid ${COLORS.LIGHT_DIVIDER};
        padding: 8px 0px;
        color: ${(props) => props.theme.colors.buttonContrast};
        margin-bottom: 1em;
    }
`;

const PlaylistTable = ({ musics, fetchMusics }) => {
    const [sortType, setSortType] = useState({ title: 'asc', artist: 'asc', createdAt: 'asc' });
    const [sortCol, setSortCol] = useState('title');

    const [sortedData, setSortedData] = useState(
        musics.map((music) => {
            if (!music.title) {
                music.title = 'Unknown';
            }
            if (!music?.artists) {
                music.artist = 'Unknown';
            } else {
                if (!typeof music.artists === 'string' && music.artists.length > 0) {
                    music.artist = music.artists[0];
                } else {
                    music.artist = music.artists;
                }
            }
        }),
    );

    const handleRemoveMusic = async (id) => {
        try {
            await app.service('music').remove(id);
            fetchMusics();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (musics) {
            if (sortType[`${sortCol}`] === 'asc') {
                setSortedData([
                    ...musics?.sort((a, b) => (a[`${sortCol}`] > b[`${sortCol}`] ? 1 : b[`${sortCol}`] > a[`${sortCol}`] ? -1 : 0)),
                ]);
            } else {
                setSortedData([
                    ...musics?.sort((a, b) => (a[`${sortCol}`] > b[`${sortCol}`] ? -1 : b[`${sortCol}`] > a[`${sortCol}`] ? 1 : 0)),
                ]);
            }
        }
    }, [musics, sortType, sortCol]);

    const handleSortChange = (e) => {
        setSortCol(e.target.id);
        setSortType((current) => {
            const tmp = { ...current };
            tmp[e.target.id] = current[e.target.id] === 'asc' ? 'desc' : 'asc';
            return tmp;
        });
    };

    const headers = [
        {
            id: 'title',
            width: '30%',
            name: (
                <div style={{ display: 'flex' }}>
                    <span>Title</span>
                    <div onClick={handleSortChange} className="pointer" style={{ marginLeft: '10px' }}>
                        <img
                            style={{
                                transition: '.3s',
                                transform: sortType.title === 'asc' ? null : 'rotate(180deg)',
                            }}
                            id="title"
                            width="15px"
                            height="15px"
                            src="/assets/down-arrow.png"
                            alt=""
                        />
                    </div>
                </div>
            ),
        },
        {
            id: 'artist',
            width: '25%',
            name: (
                <div style={{ display: 'flex' }}>
                    <span>Artist</span>
                    <div onClick={handleSortChange} className="pointer" style={{ marginLeft: '10px' }}>
                        <img
                            style={{
                                transition: '.3s',
                                transform: sortType.artist === 'asc' ? null : 'rotate(180deg)',
                            }}
                            id="artist"
                            width="15px"
                            height="15px"
                            src="/assets/down-arrow.png"
                            alt=""
                        />
                    </div>
                </div>
            ),
        },
        {
            id: 'date',
            width: '20%',
            name: (
                <div style={{ display: 'flex' }}>
                    <span>Date added</span>
                    <div onClick={handleSortChange} className="pointer" style={{ marginLeft: '10px' }}>
                        <img
                            style={{
                                transition: '.3s',
                                transform: sortType.createdAt === 'asc' ? null : 'rotate(180deg)',
                            }}
                            id="createdAt"
                            width="15px"
                            height="15px"
                            src="/assets/down-arrow.png"
                            alt=""
                        />
                    </div>
                </div>
            ),
        },
        { id: 'duration', width: '10%', name: 'Time' },
        { id: 'options', width: '15%', name: '' },
    ];

    return (
        <StyledTable style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="header" style={{ width: '100%', display: 'flex' }}>
                {headers.map((column) => (
                    <div key={column.id} style={{ width: column.width }}>
                        {column.name}
                    </div>
                ))}
            </div>
            <CustomScrollbarWrapper padding="0px" boxHeight="100%">
                {sortedData?.map((music, i) => (
                    <div key={i} className="row">
                        <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                            <span className="cut-text">{music.title}</span>
                        </div>
                        <div style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
                            <span className="cut-text">{music.artist}</span>
                        </div>
                        <div style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
                            <span className="cut-text">{moment(music.createdAt).format('L')}</span>
                        </div>
                        <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                            <span className="cut-text">{moment.utc(parseInt(music.duration) * 1000).format('mm:ss')}</span>
                        </div>
                        <div style={{ width: '15%', display: 'flex', justifyContent: 'flex-end' }}>
                            {music.action === 1 && <img alt="trash" src="/assets/Icon/like.png" width="25px" height="25px" />}
                            {music.action === 2 && <img alt="trash" src="/assets/Icon/dislike.png" width="25px" height="25px" />}
                            <img
                                onClick={() => handleRemoveMusic(music.idMusic)}
                                className="pointer"
                                alt="trash"
                                src="/assets/Icon/trash.png"
                                width="25px"
                                height="25px"
                                style={{ marginRight: 10 }}
                            />
                        </div>
                    </div>
                ))}
            </CustomScrollbarWrapper>
        </StyledTable>
    );
};

export default PlaylistTable;
