import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import COLORS from '../constants/COLORS';
import Button from '../global/components/Button';
import Input from '../global/components/Input';
import Text from '../global/components/Text';
import Spacer from '../global/components/Spacer';
import Title from '../global/components/Title';
import FlexContainer from '../global/containers/FlexContainer';
import CustomImagePicker from '../global/components/CustomImagePicker';
import { useStore } from '../context/dataContext';
import app from '../utils/feathersClient';
import { useModal } from '../context/modalContext';

const CreatePlaylistForm = () => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);

    const { selectedUser, setUserPlaylists } = useStore();
    const { close } = useModal();

    const handlePlaylistCreation = async () => {
        try {
            const reader = new FileReader();
            if (image) {
                reader.onload = async (e) => {
                    await app.service('playlist').create({ idUser: selectedUser, name, uri: e.target.result });
                    const res = await app.service('playlist').find({ query: { idUser: selectedUser, $sort: { createdAt: 1 } } });
                    setUserPlaylists(res);
                    close();
                };
                reader.readAsDataURL(image);
            } else {
                await app.service('playlist').create({ idUser: selectedUser, name });
                const res = await app.service('playlist').find({ query: { idUser: selectedUser, $sort: { createdAt: 1 } } });
                setUserPlaylists(res);
                close();
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <FlexContainer direction="column" justifyContent="center" alignItems="center" onClick={(e) => e.stopPropagation()}>
            <Title color={COLORS.WHITE} fontSize="35px" style={{ borderBottom: '1px white solid', padding: '0px 50px 25px 50px' }}>
                Create a new playlist
            </Title>
            <CustomImagePicker height="200px" width="200px" getImage={setImage} />
            <Spacer height="20px" />
            <FlexContainer alignItems="center">
                <Text fontSize="30px" margin="0px 20px 0px 0px">
                    Name
                </Text>
                <Input value={name} onChange={(e) => setName(e.target.value)} fromModal placeholder="Name" />
            </FlexContainer>
            <Spacer height="20px" />
            <Button rounded fontSize="35px" fromModal primaryColor="transparent" onClick={handlePlaylistCreation} disabled={!name}>
                Create
            </Button>
        </FlexContainer>
    );
};

export default CreatePlaylistForm;
