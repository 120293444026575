import React from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/COLORS';
import FlexContainer from '../containers/FlexContainer';
import Text from './Text';

const StyledInput = styled.input`
    background-color: ${(props) => (props.fromModal ? 'transparent' : COLORS.BLACK)};
    padding: 10px;
    border: 1px white solid;
    border-radius: 20px;
    color: ${COLORS.WHITE};
    margin-bottom: ${(props) => (props.fromModal ? null : '20px')};
    font-size: ${(props) => (props.fromModal ? '25px' : null)};

    :focus {
        outline: none;
        cursor: text;
    }

    ::placeholder {
        color: ${COLORS.WHITE};
    }
`;

const Input = ({ placeholder, type, label, value, onChange, fromModal }) => {
    if (label)
        return (
            <FlexContainer direction="column">
                <Text fontSize="15px" margin="0px 0px 0px 10px">
                    {label}
                </Text>
                <StyledInput fromModal={fromModal} value={value} onChange={onChange} type={type} placeholder={placeholder} />
            </FlexContainer>
        );
    return <StyledInput fromModal={fromModal} value={value} onChange={onChange} type={type} placeholder={placeholder} />;
};

export default Input;
