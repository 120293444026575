import React from 'react';
import Routes from './router/Routes';
import { ThemeProvider } from 'styled-components';
import useAutologin from './hooks/useAutoLogin';
import { darkTheme } from './theme/themes';
import COLORS from './constants/COLORS';
import styled from 'styled-components';
import { useModal } from './context/modalContext';
import FlexContainer from './global/containers/FlexContainer';
import Text from './global/components/Text';
import BasicContainer from './global/containers/BasicContainer';

const StyledModal = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.BLACK.withOpacity(0.75)};
    z-index: 3;
    padding: 100px;
    box-sizing: border-box;

    .cross {
        color: white;
        cursor: pointer;
    }
`;

const Modal = () => {
    const { modalContent, close } = useModal();

    if (!modalContent) return null;
    return (
        <StyledModal onClick={close}>
            <FlexContainer justifyContent="flex-end" margin="0px 0px 40px 0px">
                <Text onClick={close} fontSize="20px" bold className="cross">
                    X
                </Text>
            </FlexContainer>
            <BasicContainer height="100%" width="100%" backgroundColor="transparent">
                {modalContent}
            </BasicContainer>
        </StyledModal>
    );
};

const App = () => {
    const load = useAutologin();

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal />
            {!load && <Routes />}
        </ThemeProvider>
    );
};

export default App;
