import React, { useState, useContext, createContext } from 'react';
import app from '../utils/feathersClient';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const autoLogin = async () => {
        try {
            await app.reAuthenticate();
            setIsAuthenticated(true);
        } catch (e) {
            console.log(e);
        }
    };

    const login = async (email, password) => {
        try {
            await app.authenticate({ username: email, password, strategy: 'local' });
            setIsAuthenticated(true);
        } catch (e) {
            setLoginError(e?.code === 401 ? 'Username or password incorrect' : 'Unable to connect');
        }
    };

    const logout = async () => {
        try {
            await app.logout();
            localStorage.removeItem('auth');
        } catch (e) {
            console.log(e);
        }
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                loginError,
                setLoginError,
                login,
                autoLogin,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
