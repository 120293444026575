import React from 'react';
import styled from 'styled-components';

const StyledSpacer = styled.span`
    margin: ${(props) => `${props.height} ${props.width} ${props.height} ${props.width}`};
`;

const Spacer = ({ width = '20px', height = '20px' }) => {
    return <StyledSpacer width={width} height={height} />;
};

export default Spacer;
