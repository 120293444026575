import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/COLORS';
import FlexContainer from '../containers/FlexContainer';

const StyledImagePicker = styled(FlexContainer)`
    background-color: ${COLORS.IMAGE_PICKER};
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    border-radius: 20px;

    input {
        display: none;
    }
`;

const CustomImagePicker = ({ height = '200px', width = '400px', getImage = () => {}, src }) => {
    const inputRef = useRef(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const handleImageAccept = (e) => {
        const reader = new FileReader();

        if (e.target.files.length > 0) {
            getImage(e.target.files[0]);
            reader.onload = (e) => {
                setPreviewUrl(e.target.result);
            };
            if (e.target.files.length !== 0) reader.readAsDataURL(e.target.files[0]);
        }
    };

    return (
        <StyledImagePicker
            height={height}
            width={width}
            className="pointer"
            justifyContent="center"
            alignItems="center"
            onClick={() => inputRef.current.click()}
        >
            <img height="100%" src={previewUrl || src || '/assets/Icon/add-picture.png'}></img>
            <input accept="image/*" type="file" onChange={handleImageAccept} ref={inputRef} />
        </StyledImagePicker>
    );
};

export default CustomImagePicker;
