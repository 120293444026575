import React from 'react';
import styled from 'styled-components';

const StyledFlexContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction};
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    flex-wrap: ${(props) => props.flexWrap};
    flex: ${(props) => props.flex};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
`;

const FlexContainer = ({
    direction = 'row',
    alignItems = 'flex-start',
    justifyContent = 'flex-start',
    flexWrap = 'nowrap',
    flex = '0 1 auto',
    width = 'auto',
    height = 'auto',
    padding = '0px',
    margin = '0px',
    children,
    style,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
    ref,
}) => {
    return (
        <StyledFlexContainer
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}
            flexWrap={flexWrap}
            flex={flex}
            width={width}
            height={height}
            padding={padding}
            margin={margin}
            style={style}
            className={className}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            {children}
        </StyledFlexContainer>
    );
};

export default FlexContainer;
