import React, { useState } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import COLORS from '../constants/COLORS';
import { useAuth } from '../context/authContext';
import Button from '../global/components/Button';
import Input from '../global/components/Input';
import Text from '../global/components/Text';
import { isMobile } from 'react-device-detect';

const StyledLoginPage = styled.form`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.colors.background};

    > span {
        margin-bottom: 10px;
    }
`;

const Login = () => {
    const { login, isAuthenticated, loginError } = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    if (isAuthenticated) return <Redirect to="/dashboard" />;
    return (
        <StyledLoginPage
            onSubmit={(e) => {
                e.preventDefault();
                login(username, password);
            }}
            direction="column"
            alignItems="center"
            height="100%"
            width="100%"
        >
            <img width={isMobile ? '100%' : '25%'} alt="Logo principal" src="/assets/Logo/logo-full.jpg" />
            <Input onChange={(e) => setUsername(e.target.value)} value={username} placeholder="Username" label="Username" />
            <Input onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Password" type="password" label="Password" />
            {loginError && (
                <Text bold color={COLORS.RED}>
                    {loginError}
                </Text>
            )}
            <Button type="submit" rounded fontSize="16px" inverted onClick={() => login(username, password)}>
                Login
            </Button>
        </StyledLoginPage>
    );
};

export default Login;
