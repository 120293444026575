import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import { ModalProvider } from './context/modalContext';
import { DataProvider } from './context/dataContext';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <DataProvider>
                <ModalProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </ModalProvider>
            </DataProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
