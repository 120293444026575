import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import COLORS from '../../constants/COLORS';
import { useAuth } from '../../context/authContext';
import Button from '../../global/components/Button';
import Title from '../../global/components/Title';
import BasicContainer from '../../global/containers/BasicContainer';
import FlexContainer from '../../global/containers/FlexContainer';
import PlaylistTable from '../../global/containers/PlaylistTable';
import styled from 'styled-components';
import app from '../../utils/feathersClient';
import { useStore } from '../../context/dataContext';
import ClipLoader from 'react-spinners/ClipLoader';

const StyledDropzone = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 1px solid ${COLORS.LIGHT_DIVIDER};
`;

const Musics = () => {
    const { logout } = useAuth();
    const [musics, setMusics] = useState([]);
    const [loading, setLoading] = useState(false);

    const { selectedPlaylist } = useStore();

    const handleMusicUpload = async (files) => {
        setLoading(true);
        await Promise.all(
            files.map((music) => {
                try {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        if (music) {
                            reader.onload = async (e) => {
                                try {
                                    await app.service('music').create({ idPlaylist: selectedPlaylist, uri: e.target.result });
                                    resolve();
                                } catch (e) {
                                    reject();
                                    console.log(e);
                                }
                            };
                            reader.onerror = reject;
                            reader.readAsDataURL(music);
                        }
                    });
                } catch (e) {
                    console.log(e);
                }
            }),
        );
        await fetchMusics();

        setLoading(false);
    };

    const fetchMusics = async () => {
        try {
            const res = await app.service('playlist').get(selectedPlaylist, { query: { $sort: { createdAt: 1 } }, paginate: false });
            setMusics(res?.music);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (selectedPlaylist) fetchMusics();
        else setMusics([]);
    }, [selectedPlaylist]);

    return (
        <BasicContainer width="100%" padding="30px" height="100%">
            <FlexContainer justifyContent="space-between" height="10%">
                <FlexContainer alignItems="center" height="100%">
                    <Title as="h2" fontSize="30px" noMargin>
                        MUSICS
                    </Title>
                </FlexContainer>
                <Button onClick={logout} fontSize="16px">
                    Log out
                </Button>
            </FlexContainer>
            <div style={{ height: '65%' }}>
                <PlaylistTable fetchMusics={fetchMusics} musics={musics} />
            </div>
            <BasicContainer padding="20px 0px" height="25%">
                <Dropzone onDrop={(acceptedFiles) => handleMusicUpload(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <StyledDropzone {...getRootProps()}>
                            <div height="100%">
                                {!loading ? (
                                    <>
                                        <input {...getInputProps()} />
                                        <Button icon={{ src: '/assets/Icon/add.png', width: '50px', height: '50px' }}>Add Musics</Button>
                                    </>
                                ) : (
                                    <ClipLoader color={COLORS.GREY} loading={loading} size={100} />
                                )}
                            </div>
                        </StyledDropzone>
                    )}
                </Dropzone>
            </BasicContainer>
        </BasicContainer>
    );
};

export default Musics;
