import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const AuthenticatedRoute = ({ children, path, exact }) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? (
        <Route path={path} exact={exact}>
            {children}
        </Route>
    ) : (
        <Redirect to="/login" />
    );
};

export default AuthenticatedRoute;
