import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import COLORS from '../../constants/COLORS';
import { useStore } from '../../context/dataContext';
import { useModal } from '../../context/modalContext';
import CreatePlaylistForm from '../../forms/CreatePlaylistForm';
import EditPlaylistForm from '../../forms/EditPlaylistForm';
import Button from '../../global/components/Button';
import ListItem from '../../global/components/ListItem';
import Text from '../../global/components/Text';
import Title from '../../global/components/Title';
import BasicContainer from '../../global/containers/BasicContainer';
import CustomScrollbarWrapper from '../../global/containers/CustomScrollbarWrapper';
import FlexContainer from '../../global/containers/FlexContainer';
import StyledUserContextMenu from '../../global/containers/StyledContextMenu';
import { ligthTheme } from '../../theme/themes';
import app from '../../utils/feathersClient';

const playlists = [
    { selected: false, name: 'Brunch' },
    { selected: false, name: 'Lunch' },
    { selected: true, name: 'Diner' },
    { selected: false, name: 'After' },
    { selected: false, name: 'Chill' },
    { selected: false, name: 'Brunch' },
    { selected: false, name: 'Lunch' },
    { selected: false, name: 'Diner' },
    { selected: false, name: 'After' },
    { selected: false, name: 'Chill' },
    { selected: false, name: 'Brunch' },
    { selected: false, name: 'Lunch' },
    { selected: false, name: 'Diner' },
    { selected: false, name: 'After' },
    { selected: false, name: 'Chill' },
];

const PlaylistContextMenu = ({ playlist }) => {
    const { open } = useModal();
    const { setUserPlaylists, selectedUser, selectedPlaylist, setSelectedPlaylist } = useStore();

    const options = [
        { name: 'Update', effect: () => open(<EditPlaylistForm playlist={playlist} />) },
        {
            name: 'Delete',
            effect: async () => {
                await app.service('playlist').remove(playlist.idPlaylist);
                const res = await app.service('playlist').find({
                    query: {
                        idUser: selectedUser,
                        $sort: {
                            createdAt: 1,
                        },
                    },
                });
                setUserPlaylists(res);
                if (playlist.idPlaylist === selectedPlaylist) {
                    setSelectedPlaylist(null);
                }
            },
        },
    ];

    return (
        <ThemeProvider theme={ligthTheme}>
            <StyledUserContextMenu direction="column">
                {options.map((option) => (
                    <div key={option.name} onClick={option.effect} className="option-container pointer">
                        <Text>{option.name}</Text>
                    </div>
                ))}
            </StyledUserContextMenu>
        </ThemeProvider>
    );
};

const Playlists = () => {
    const { open } = useModal();
    const { setUserPlaylists, userPlaylists, selectedUser, setSelectedPlaylist, selectedPlaylist } = useStore();

    const [sortType, setSortType] = useState('asc');
    const [sortedData, setSortedData] = useState(userPlaylists);

    useEffect(() => {
        const fetchPlaylist = async () => {
            try {
                const res = await app.service('playlist').find({
                    query: {
                        idUser: selectedUser,
                        $sort: {
                            createdAt: 1,
                        },
                    },
                });
                setUserPlaylists(res);
                if (res.length > 0) setSelectedPlaylist(res[0]?.idPlaylist);
            } catch (e) {
                console.log(e);
            }
        };
        setSelectedPlaylist(null);
        if (selectedUser) fetchPlaylist();
    }, [selectedUser]);

    useEffect(() => {
        if (userPlaylists) {
            if (sortType === 'asc') {
                setSortedData([...userPlaylists?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))]);
            } else {
                setSortedData([...userPlaylists?.sort((a, b) => (a.name > b.name ? -1 : b.name > a.name ? 1 : 0))]);
            }
        }
    }, [userPlaylists, sortType]);

    const handleSortChange = () => {
        setSortType((current) => (current === 'asc' ? 'desc' : 'asc'));
    };

    return (
        <BasicContainer padding="30px" width="100%" height="100%">
            <FlexContainer height="10%" alignItems="center">
                <Title as="h2" fontSize="30px" noMargin>
                    PLAYLISTS
                </Title>
                <div onClick={handleSortChange} className="pointer" style={{ padding: 10, marginTop: 'auto' }}>
                    <img
                        style={{
                            transition: '.3s',
                            transform: sortType === 'asc' ? null : 'rotate(180deg)',
                        }}
                        width="15px"
                        height="15px"
                        src="/assets/down-arrow.png"
                        alt=""
                    />
                </div>
            </FlexContainer>

            <CustomScrollbarWrapper dividerColor={COLORS.LIGHT_DIVIDER} boxHeight="80%">
                {sortedData?.map((p, i) => (
                    <ListItem
                        onSelect={() => setSelectedPlaylist(p.idPlaylist)}
                        selected={selectedPlaylist === p.idPlaylist}
                        item={p}
                        key={i}
                        dividerColor={COLORS.LIGHT_DIVIDER}
                        contextMenu={<PlaylistContextMenu playlist={p} />}
                    />
                ))}
            </CustomScrollbarWrapper>
            <FlexContainer height="10%" justifyContent="center" alignItems="flex-end">
                <Button icon={{ src: '/assets/Icon/add.png', width: '50px', height: '50px' }} onClick={() => open(<CreatePlaylistForm />)}>
                    Add playlist
                </Button>
            </FlexContainer>
        </BasicContainer>
    );
};

export default Playlists;
