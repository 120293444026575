import React, { useState, useRef } from 'react';
import Button from '../global/components/Button';
import Input from '../global/components/Input';
import Text from '../global/components/Text';
import Spacer from '../global/components/Spacer';
import FlexContainer from '../global/containers/FlexContainer';
import CustomImagePicker from '../global/components/CustomImagePicker';
import app from '../utils/feathersClient';
import { useStore } from '../context/dataContext';
import { useModal } from '../context/modalContext';

const EditUserForm = ({ etab }) => {
    const [name, setName] = useState(etab.name);
    const [image, setImage] = useState(null);
    const [password, setPassword] = useState('');

    const { setEtab } = useStore();
    const { close } = useModal();

    const handleProfilEdition = async () => {
        try {
            const reader = new FileReader();
            if (image) {
                reader.onload = async (e) => {
                    await app.service('users').patch(etab.idUser, { name, uri: e.target.result, password });
                    const res = await app.service('users').find({ query: { isAdmin: false } });
                    setEtab(res);
                    close();
                };
                reader.readAsDataURL(image);
            } else {
                await app.service('users').patch(etab.idUser, { name, password });
                const res = await app.service('users').find({ query: { isAdmin: false } });
                setEtab(res);
                close();
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <FlexContainer direction="column" justifyContent="center" alignItems="center" onClick={(e) => e.stopPropagation()}>
            <CustomImagePicker src={etab.picture} getImage={setImage} />
            <Spacer height="20px" />
            <FlexContainer alignItems="center">
                <Text fontSize="30px" margin="0px 20px 0px 0px">
                    Name
                </Text>
                <Input value={name} onChange={(e) => setName(e.target.value)} fromModal placeholder="Name" />
            </FlexContainer>
            <Spacer height="20px" />
            <FlexContainer alignItems="center">
                <Text fontSize="30px" margin="0px 20px 0px 0px">
                    Password
                </Text>
                <Input value={password} onChange={(e) => setPassword(e.target.value)} fromModal placeholder="New password" />
            </FlexContainer>
            <Spacer height="20px" />
            <Button rounded fontSize="35px" fromModal primaryColor="transparent" onClick={handleProfilEdition} disabled={!name}>
                Update
            </Button>
        </FlexContainer>
    );
};

export default EditUserForm;
