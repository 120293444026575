import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import COLORS from '../../constants/COLORS';
import { useStore } from '../../context/dataContext';
import { useModal } from '../../context/modalContext';
import CreateUserForm from '../../forms/CreateUserForm';
import EditUserForm from '../../forms/EditUserForm';
import { ligthTheme } from '../../theme/themes';
import app from '../../utils/feathersClient';
import BasicContainer from '../containers/BasicContainer';
import CustomScrollbarWrapper from '../containers/CustomScrollbarWrapper';
import FlexContainer from '../containers/FlexContainer';
import StyledUserContextMenu from '../containers/StyledContextMenu';
import Button from './Button';
import ListItem from './ListItem';
import Text from './Text';

const etab = [
    { selected: false, name: 'Chill Bros' },
    { selected: false, name: 'Leonelli Café' },
    { selected: true, name: 'Canoe Place' },
    { selected: false, name: 'Shun' },
    { selected: false, name: 'Chill Bros' },
    { selected: false, name: 'Leonelli Café' },
    { selected: false, name: 'Canoe Place' },
    { selected: false, name: 'Shun' },
    { selected: false, name: 'Chill Bros' },
    { selected: false, name: 'Leonelli Café' },
    { selected: false, name: 'Canoe Place' },
    { selected: false, name: 'Shun' },
];

const UserContextMenu = ({ etab }) => {
    const { open } = useModal();
    const { setEtab } = useStore();

    const options = [
        { name: 'Update', effect: () => open(<EditUserForm etab={etab} />) },
        {
            name: 'Delete',
            effect: async () => {
                await app.service('users').remove(etab.idUser);
                const res = await app.service('users').find({ query: { isAdmin: false } });
                setEtab(res);
            },
        },
    ];

    return (
        <ThemeProvider theme={ligthTheme}>
            <StyledUserContextMenu direction="column">
                {options.map((option) => (
                    <div key={option.name} onClick={option.effect} className="option-container pointer">
                        <Text>{option.name}</Text>
                    </div>
                ))}
            </StyledUserContextMenu>
        </ThemeProvider>
    );
};

const Menu = () => {
    const { open } = useModal();
    const { setEtab, etab, selectedUser, setSelectedUser } = useStore();
    const [sortType, setSortType] = useState('asc');
    const [sortedData, setSortedData] = useState(etab);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await app.service('users').find({ query: { isAdmin: false } });
                setEtab(res);
            } catch (e) {}
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        if (etab) {
            if (sortType === 'asc') {
                setSortedData([...etab?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))]);
            } else {
                setSortedData([...etab?.sort((a, b) => (a.name > b.name ? -1 : b.name > a.name ? 1 : 0))]);
            }
        }
    }, [etab, sortType]);

    const handleSortChange = () => {
        setSortType((current) => (current === 'asc' ? 'desc' : 'asc'));
    };

    return (
        <BasicContainer padding="30px" width="100%" height="100%">
            <FlexContainer height="10%" justifyContent="center" alignItems="center">
                <img alt="Logo Menu" src="/assets/Logo/logo-small.png" width="150px" />
                <div onClick={handleSortChange} className="pointer" style={{ padding: 10, marginTop: 'auto' }}>
                    <img
                        style={{
                            transition: '.3s',
                            transform: sortType === 'asc' ? null : 'rotate(180deg)',
                            filter: 'brightness(0) invert(1)',
                        }}
                        width="15px"
                        height="15px"
                        src="/assets/down-arrow.png"
                        alt=""
                    />
                </div>
            </FlexContainer>
            <CustomScrollbarWrapper dividerColor={COLORS.DARK_DIVIDER} boxHeight="80%">
                {sortedData?.map((e, i) => (
                    <ListItem
                        onSelect={() => setSelectedUser(e.idUser)}
                        contextMenu={<UserContextMenu etab={e} />}
                        item={e}
                        key={i}
                        dividerColor={COLORS.DARK_DIVIDER}
                        selected={selectedUser === e.idUser}
                    />
                ))}
            </CustomScrollbarWrapper>
            <FlexContainer height="10%" justifyContent="center" alignItems="flex-end">
                <Button
                    onClick={() => open(<CreateUserForm />)}
                    invertFilter
                    icon={{ src: '/assets/Icon/add.png', width: '50px', height: '50px' }}
                >
                    Add new
                </Button>
            </FlexContainer>
        </BasicContainer>
    );
};

export default Menu;
