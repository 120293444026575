import styled from 'styled-components';
import COLORS from '../../constants/COLORS';
import FlexContainer from './FlexContainer';

const StyledUserContextMenu = styled(FlexContainer)`
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.27);

    span {
        font-size: 20px;
    }

    .option-container {
        width: 100%;
        box-sizing: border-box;
        padding: 5px;

        &:hover {
            background-color: ${COLORS.GREY.withOpacity(0.15)};
        }
    }
`;

export default StyledUserContextMenu;
